import React from 'react';
import Content from '../views/Fleet/Price';

export default ({location}) => (
  <Content location={location} seo={{
    title: 'Hvad koster flådestyring? Få en pris på vores tjenester',
    lang: 'da-DK',
    description: 'Vil du have en pris på flådestyring? Udfyld vores korte formular og modtag et tilbud.',
    meta: [{
      name: 'keywords',
      content: 'Flådestyring af biler'
    }]
  }}/>
);